import React from 'react'

function WhoWeAreEn() {
  return (
    <div class='privacy-html'>
        <h2>
            Who We Are - NeoArts
        </h2>
        <p>
            At NeoArts, we are passionately dedicated to creating and developing innovative technological solutions that drive the success of our clients in an ever-evolving digital world. Our commitment goes beyond simply creating websites and custom software; we strive to be strategic partners who understand the specific needs of each client and provide customized solutions that exceed their expectations.
        </p>
        <br/>
        <h4>
            Our Specialization
        </h4>
        <p>
            At the heart of our mission is specialization in creating modern and responsive websites. We take pride in designing captivating online experiences that not only meet current standards but also anticipate future trends. Additionally, we excel in custom software development, adapting to the unique needs of each client to deliver technological solutions that enhance their operations and business objectives.
        </p>
        <br/>
        <h4>
            Robust Information Systems
        </h4>
        <p>
            At NeoArts, we create robust information systems that enable our clients to efficiently manage and analyze data. We implement cutting-edge technologies and agile methodologies to ensure that our systems are scalable, secure, and keep pace with ever-changing business demands.
        </p>
        <br/>
        <h4>
            Innovative Digital Marketing
        </h4>
        <p>
            We understand the importance of a strong digital presence in today's market. Our team of digital marketing experts works closely with our clients to develop innovative strategies that maximize their online visibility and generate tangible results. We stay at the forefront of market trends, ensuring that each strategy is adaptive and effective.
        </p>
        <br/>
        <h4>
            Commitment to Quality and Excellence
        </h4>
        <p>
            At NeoArts, quality is a fundamental priority. We are committed to using cutting-edge technologies and best development practices to deliver products and services of the highest quality. We are driven by efficiency and excellence in service, establishing long-term relationships with our clients based on trust and mutual success.
        </p>
        <br/>
        <h4>
        Our Vision
        </h4>
        <p>
            Our vision is to be leaders in the technology solutions industry, known for our innovation, work ethic, and commitment to customer satisfaction. At NeoArts, we are ready to take your company to the next level in the digital world. Join us on this exciting technological journey!
        </p>
    </div>
  )
}

export default WhoWeAreEn