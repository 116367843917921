import React from 'react'

function WhoWeAreEs() {
  return (
    <div class='privacy-html'>
        <h2>
            ¿Quiénes somos? - NeoArts
        </h2>
        <p>
            En NeoArts, nos dedicamos apasionadamente a la creación y desarrollo de soluciones tecnológicas innovadoras que impulsan el éxito de nuestros clientes en un mundo digital en constante evolución. Nuestro compromiso va más allá de la simple creación de páginas web y software a medida; nos esforzamos por ser socios estratégicos que comprenden las necesidades específicas de cada cliente y proporcionan soluciones personalizadas que superan sus expectativas.
        </p>
        <br/>
        <h4>
            Nuestra Especialización
        </h4>
        <p>
            En el corazón de nuestra misión se encuentra la especialización en la creación de páginas web modernas y responsivas. Nos enorgullece diseñar experiencias online cautivadoras que no solo cumplen con los estándares actuales, sino que también anticipan las tendencias futuras. Además, destacamos en el desarrollo de software personalizado, adaptándonos a las necesidades únicas de cada cliente para ofrecer soluciones tecnológicas que potencien sus operaciones y objetivos comerciales.
        </p>
        <br/>
        <h4>
            Sistemas de Información Robustos
        </h4>
        <p>
            En NeoArts, creamos sistemas de información robustos que permiten a nuestros clientes gestionar y analizar datos de manera eficiente. Implementamos tecnologías de vanguardia y metodologías ágiles para garantizar que nuestros sistemas sean escalables, seguros y se mantengan al ritmo de las demandas empresariales en constante cambio.
        </p>
        <br/>
        <h4>
            Marketing Digital Innovador
        </h4>
        <p>
            Entendemos la importancia de una presencia digital sólida en el mercado actual. Nuestro equipo de expertos en marketing digital trabaja en estrecha colaboración con nuestros clientes para desarrollar estrategias innovadoras que maximicen su visibilidad online y generen resultados tangibles. Nos mantenemos a la vanguardia de las tendencias del mercado, asegurando que cada estrategia sea adaptativa y efectiva.
        </p>
        <br/>
        <h4>
            Compromiso con la Calidad y la Excelencia
        </h4>
        <p>
            En NeoArts, la calidad es una prioridad fundamental. Nos comprometemos a utilizar tecnologías de vanguardia y las mejores prácticas de desarrollo para ofrecer productos y servicios de la más alta calidad. Estamos impulsados por la eficiencia y la excelencia en el servicio, estableciendo relaciones a largo plazo con nuestros clientes basadas en la confianza y el éxito mutuo.
        </p>
        <br/>
        <h4>
        Nuestra Visión
        </h4>
        <p>
            Nuestra visión es ser líderes en la industria de soluciones tecnológicas, destacando por nuestra innovación, ética de trabajo y compromiso con la satisfacción del cliente. En NeoArts, estamos listos para llevar a su empresa al siguiente nivel en el mundo digital. ¡Únase a nosotros en esta emocionante travesía tecnológica!
        </p>
    </div>
  )
}

export default WhoWeAreEs